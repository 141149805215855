



























































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'number-recruit-draggable': () => import('@/modules/recruitment/dialogs/forms/number-recruit-draggable.vue'),
    'add-social-card': () => import('@/modules/recruitment/dialogs/forms/add-social-card.vue'),
  },
})
export default class extends Vue {
  @Prop({
    default: () => {
      return {}
    },
  })
  controller

  @Prop({ default: false }) notEditable

  showGroupArea = true

  get heightInput() {
    return this.$vuetify.breakpoint.smOnly ? 44 : 50
  }
  get bgInput() {
    return this.$vuetify.breakpoint.smOnly ? 'primary darken-1' : 'primary darken-2'
  }
}
